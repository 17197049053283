<template>
  <div class="cont">
    <div class="emails-list">
      <div class="cont" v-if="emailList.length">
        <div
          v-for="email in emailList"
          :key="email.id"
          class="emails-details"
          :class="{ selectedDetails: email.selected }"
        >
          <div class="details-checkbox">
            <input
              type="checkbox"
              @click="emailCheckbox(email.id)"
              :checked="email.checked"
            />
          </div>
          <div v-if="email.star" @click="star(email.id)" class="details-star">
            <b-icon icon="star-fill" style="color: rgb(255,153,0)"></b-icon>
          </div>
          <div
            v-if="!email.star"
            @click="star(email.id)"
            class="details-star inactive-icon"
          >
            <b-icon icon="star"></b-icon>
          </div>

          <div
            v-if="email.attachment"
            @click="detailsClicked(email.id)"
            class="details-attachment"
          >
            <b-icon icon="link45deg"></b-icon>
          </div>
          <div
            v-if="!email.attachment"
            @click="detailsClicked(email.id)"
            class="details-attachment inactive-icon"
          >
            <b-icon icon="link45deg"></b-icon>
          </div>
          <div class="details-trash" @click="deleteMailModal(email)">
            <span class="trash-icon"><b-icon icon="trash"></b-icon></span>
          </div>
          <div
            v-if="email.fromname"
            class="details-from"
            @click="detailsClicked(email.id)"
            :class="{ unread: email.unread }"
          >
            {{ email.fromname }}
          </div>
          <div v-if="!email.fromname"
            class="details-from"
            @click="detailsClicked(email.id)"
            :class="{ unread: email.unread }"
          >
            {{ email.from }}
          </div>
          <div
            class="details-subject"
            @click="detailsClicked(email.id)"
            :class="{ unread: email.unread }"
          >
            {{ email.subject }}
          </div>
          <div
            class="details-datum"
            @click="detailsClicked(email.id)"
            :class="{ unread: email.unread }"
          >
            {{ email.datum }}
          </div>
        </div>
      </div>
      <h2 v-if="!emailList.length">Üres mappa!</h2>
    </div>

    <b-modal id="modal-delete-mail" title="Törlés" hide-footer>
      <h5>Biztosan törölni akarod?</h5>
      <div v-if="deleteEmailData" class="overflow-text m-3">
        <b>Feladó: </b>{{ deleteEmailData.from }}
      </div>
      <div v-if="deleteEmailData" class="overflow-text m-3">
        <b>Tárgy: </b>{{ deleteEmailData.subject }}
      </div>
      <div v-if="!deleteEmailData" class="overflow-text buttons">
        Biztosan törlöd a 
        {{ emailList.filter(e => e.checked).length }}db kijelölt emailt?
      </div>
      <b-button class="buttons" variant="primary" @click="closeModal"
        >Mégse</b-button
      >
      <b-button class="buttons" variant="primary" @click="deleteEmail"
        >Törlés</b-button
      >
    </b-modal>

    <b-modal id="modal-move-mail" title="Áthelyezés" hide-footer>
      <h4>Válassz célmappát!</h4>
      <div v-for="folder in folders" :key="folder.id">
        <div @click="moveTo(folder.folderId)" class="move-options">
          <span v-if="folder.type == 0" class="icons"
            ><b-icon icon="folder"></b-icon
          ></span>
          <span v-if="folder.type == 1" class="icons"
            ><b-icon icon="inbox"></b-icon
          ></span>
          <span v-if="folder.type == 2" class="icons"
            ><b-icon icon="trash"></b-icon
          ></span>
          <span v-if="folder.type == 3" class="icons"
            ><b-icon icon="cursor"></b-icon
          ></span>
          <span v-if="folder.type == 4" class="icons"
            ><b-icon icon="dash-circle"></b-icon
          ></span>
          <span v-if="folder.type == 5" class="icons"
            ><b-icon icon="archive"></b-icon
          ></span>
          <span v-if="folder.type == 6" class="icons"
            ><b-icon icon="pencil"></b-icon
          ></span>
          {{ folder.foldername }}
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Api from "../util/api";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      searchText: "",
      allchecked: false,
      emailList: [],
      fullEmailList: [],
      deleteEmailData: "",
      folders: [],
      selectedidArray: []
    };
  },
  watch: {
    newFolder: function(val) {
      if (val) {
        this.emailList = [];
        let self = this;
        Api.getEmails(val.email, val.folderId)
          .then(function(response) {
            let emailList = response.data;
            emailList.forEach(e => {
              e["selected"] = false;
              e["checked"] = false;
            });
            self.emailList = emailList;
            self.fullEmailList = self.emailList;
          })
          .catch(err => console.log(err));
      }
    },
    allEmailFromHeader: function(val) {
      this.emailList.forEach(e => {
        if (val) {
          e.checked = true;
        } else {
          e.checked = false;
        }
      });
      if (val) {
        this.allchecked = true;
        this.$store.commit("setAllEmailFromList", true);
        this.$store.commit("setEmailChecked", this.emailList.length);
      } else {
        this.allchecked = false;
        this.$store.commit("setAllEmailFromList", false);
        this.$store.commit("setEmailChecked", 0);
      }
    },
    emailFilter: function(filter) {
      if (filter) {
        if (filter == "unread") {
          this.emailList = this.fullEmailList;
          this.emailList = this.emailList.filter(email => email.unread);
        } else if (filter == "star") {
          this.emailList = this.fullEmailList;
          this.emailList = this.emailList.filter(email => email.star);
        } else if (filter == "attachment") {
          this.emailList = this.fullEmailList;
          this.emailList = this.emailList.filter(email => email.attachment);
        }
        this.$store.commit("setEmailFilter", false);
      }
    },
    emailSearch: function(search) {
      if (search) {
        this.emailList = this.fullEmailList;
        this.emailList = this.emailList.filter(
          email =>
            email.from.toLowerCase().search(search.toLowerCase()) !== -1 ||
            email.subject.toLowerCase().search(search.toLowerCase()) !== -1 ||
            email.datum.toLowerCase().search(search.toLowerCase()) !== -1
        );
        this.$store.commit("setEmailSearch", false);
      }
    },
    deleteAll: function() {
      this.deleteEmailData = false;
      this.$bvModal.show("modal-delete-mail");
      this.$store.commit("setDeleteAllEmails", false);
    },
    move: function() {
      if (this.move) {
        let self = this;
        Api.getAccounts()
          .then(function(response) {
            let acc = response.data;
            self.folders = acc.filter(
              e => e.email == self.newFolder.email
            )[0].folders;
            self.$bvModal.show("modal-move-mail");
          })
          .catch(err => console.log(err));
        this.$store.commit("setMove", false);
      }
    }
  },
  computed: {
    ...mapGetters([
      "newFolder",
      "allEmailFromHeader",
      "emailFilter",
      "emailSearch",
      "deleteAll",
      "move"
    ])
  },
  created() {
    let self = this;
    Api.getEmails(false, false)
      .then(function(response) {
        let emailList = response.data;
        emailList.forEach(e => {
          e["selected"] = false;
          e["checked"] = false;
        });
        self.emailList = emailList;
        self.fullEmailList = self.emailList;
      })
      .catch(err => console.log(err));
  },
  methods: {
    moveTo(folderIdTo) {
      let selectedEmailsArray = this.emailList.filter(e => e.checked);
      this.selectedidArray = [];
      selectedEmailsArray.forEach(e => {
        this.selectedidArray.push(e.id);
      });
      let email = this.newFolder.email;
      let folderIdFrom = this.newFolder.folderId;
      let self = this;
      Api.moveEmails(email, folderIdTo, folderIdFrom,this.selectedidArray)
      .then(function() {
        self.$store.commit("setRefreshAccounts", true);
        self.emailList = self.emailList.filter(e => !self.selectedidArray.includes(e.id));
        self.$bvModal.hide("modal-move-mail");
      })
      .catch(err => console.log(err));
    },
    emailCheckbox(id) {
      let task = this.emailList.find(task => task.id == id);
      task.checked = !task.checked;

      let sum = 0;
      this.emailList.forEach(e => {
        if (e.checked) {
          sum++;
        }
      });

      if (!task.checked) {
        this.allchecked = false;
        this.$store.commit("setAllEmailFromList", false);
      } else {
        if (sum == this.emailList.length) {
          this.allchecked = true;
          this.$store.commit("setAllEmailFromList", true);
        }
      }
      this.$store.commit("setEmailChecked", sum);
    },
    star(id) {
      let task = this.emailList.find(task => task.id == id);
      task.star = !task.star;
      let self = this;
      Api.star(self.newFolder.email, self.newFolder.folderId, id, task.star)
        .then(function() {})
        .catch(err => console.log(err));
    },
    detailsClicked(id) {
      this.emailList.forEach(e => {
        e.selected = false;
      });
      let task = this.emailList.find(task => task.id == id);
      task.selected = true;
      this.$store.commit("setEmailSelected", id);
    },
    deleteMailModal(email) {
      this.deleteEmailData = email;
      this.$bvModal.show("modal-delete-mail");
    },
    closeModal() {
      this.$bvModal.hide("modal-delete-mail");
    },
    deleteEmail() {
      let ids = [];
      if (this.deleteEmailData) {
        ids.push(this.deleteEmailData.id);
      } else {
        let filtered = this.emailList.filter(e => e.checked);
        filtered.forEach(e => {
          ids.push(e.id);
        });
      }
      this.$store.commit("setEmailChecked", false);
      let self = this;
      Api.deleteEmail(self.newFolder.email, self.newFolder.folderId, ids)
        .then(function() {
          if (!self.deleteEmailData) {
            self.emailList = self.emailList.filter(email => !email.checked);
          } else {
            self.emailList = self.emailList.filter(email => email.id != ids);
          }
          self.deleteEmailData = [];
          self.$bvModal.hide("modal-delete-mail");
          self.$store.commit("setRefreshAccounts", true);
        })
        .catch(err => console.log(err));
    }
  }
};
</script>

<style scoped>
.overflow-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.emails-list {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding-bottom: 20px;
}

.emails-details {
  display: flex;
  padding: 7px;
  transition: all 0.2s ease;
  border-bottom: 1px solid #eee;
}
.emails-details:hover {
  background: rgb(222, 230, 247);
  cursor: pointer;
  transition: all 0.2s ease;
}
.details-checkbox,
.details-star,
.details-attachment,
.details-trash {
  width: 20px;
}
.details-trash {
  color: #555;
}
.details-from {
  width: calc(25% - 30px);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding-right: 15px;
}
.details-subject {
  width: calc(50% - 30px);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding-right: 15px;
}
.details-datum {
  width: calc(25% - 20px);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
h2 {
  margin-top: 35px;
  text-align: center;
  color: #777;
}
.inactive-icon {
  color: #ccc;
}
.unread {
  font-weight: bold;
}
.selectedDetails {
  background: rgb(222, 230, 247);
}
h5 {
  margin-left: 15px;
}
.buttons {
  margin: 15px 0 0 15px;
}
h4 {
  text-align: center;
}
.move-options {
  margin: 5px 0;
  padding: 5px 20px;
  transition: all 0.2s ease;
}
.move-options:hover {
  background: rgb(222, 230, 247);
  cursor: pointer;
  transition: all 0.2s ease;
}
@media (max-width: 999px) {
  .emails-details {
    flex-wrap: wrap;
    border-bottom: 1px solid #ddd;
  }
  .details-checkbox {
    width: 25px;
    order: 1;
  }
  .details-star {
    width: 25px;
    order: 6;
  }
  .details-trash {
    width: 25px;
    order: 7;
  }
  .details-attachment {
    width: 25px;
    order: 5;
  }
  .details-from {
    width: calc(60% - 25px);
    order: 2;
  }
  .details-subject {
    padding-left: 25px;
    width: calc(100% - 75px);
    order: 4;
  }
  .details-datum {
    text-align: right;
    width: 40%;
    order: 3;
  }
}
</style>