<template>
  <div class="cont">
    <h3>Email fiókok</h3>
    <div v-for="account in accounts" :key="account.email" class="folder">
      <h6 class="label" @click="account.open = !account.open">
        <div v-if="!account.open" class="chevron">
          <b-icon icon="chevron-down"></b-icon>
        </div>
        <div v-if="account.open" class="chevron">
          <b-icon icon="chevron-up"></b-icon>
        </div>
        <div>{{ account.email }}</div>
        <div class="unread-nr">{{ allUnread(account.email) }}</div>
      </h6>
      <ul
        :class="{
          showContent: account.open,
          hideContent: !account.open
        }"
      >
        <li
          class="folderlist"
          v-for="folder in account.folders"
          :key="folder.id"
          @click="selectFolder(account.email, folder.foldername, folder.id)"
          :class="{
            unreadbold: parseInt(folder.unread) != 0,
            selected: folder.selected
          }"
        >
          <div v-if="folder.type == 0" class="icons">
            <b-icon icon="folder"></b-icon>
          </div>
          <div v-if="folder.type == 1" class="icons">
            <b-icon icon="inbox"></b-icon>
          </div>
          <div v-if="folder.type == 2" class="icons">
            <b-icon icon="trash"></b-icon>
          </div>
          <div v-if="folder.type == 3" class="icons">
            <b-icon icon="cursor"></b-icon>
          </div>
          <div v-if="folder.type == 4" class="icons">
            <b-icon icon="dash-circle"></b-icon>
          </div>
          <div v-if="folder.type == 5" class="icons">
            <b-icon icon="archive"></b-icon>
          </div>
          <div v-if="folder.type == 6" class="icons">
            <b-icon icon="pencil"></b-icon>
          </div>
          <div>{{ folder.foldername }}</div>
          <div v-show="parseInt(folder.unread)" class="unread-nr">
            {{ folder.unread }}
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Api from "../util/api";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      aFolderSelected: false,
      accounts: [],
      openedAccounts: []
    };
  },
  watch: {
    refreshAccounts: function(val) {
      if (val) {
        this.refresh();
      }
    }
  },
  computed: {
    ...mapGetters(["refreshAccounts", "newFolder"])
  },
  created() {
    let self = this;
    Api.getAccounts()
      .then(function(response) {
        let accounts = response.data;
        let myEmails = [];
        accounts.forEach(e => {
          myEmails.push(e.email);
          let defaultFolder = false;
          if (e.default) {
            e["open"] = true;
            defaultFolder = true;
          } else {
            e["open"] = false;
          }
          e.folders.forEach(e => {
            if (defaultFolder && e.type == "1") {
              e["selected"] = true;
            } else {
              e["selected"] = false;
            }
          });
        });
        self.$store.commit("setMyEmailAddr", myEmails);
        self.accounts = accounts;
        let email = self.accounts.filter(account => account.default)[0].email;
        let foldersInDefault = self.accounts.filter(
          account => account.default
        )[0].folders;
        foldersInDefault.forEach(e => {
          if (e.type === "1") {
            self.$store.commit("setNewFolder", {
              email: email,
              folderName: e.foldername,
              folderId: e.id
            });
          }
        });
      })
      .catch(err => console.log(err));
  },
  methods: {
    refresh() {
      this.openedAccounts = [];
      this.accounts.forEach(e => {
        if (e.open) {
          this.openedAccounts.push(e.email);
        }
      });

      let self = this;
      Api.getAccounts()
        .then(function(response) {
          let accounts = response.data;
          accounts.forEach(e => {
            if (self.openedAccounts.includes(e.email)) {
              e["open"] = true;
            } else {
              e["open"] = false;
            }

            if (e.email == self.newFolder.email) {
              e.folders.forEach(e => {
                if (e.id == self.newFolder.folderId) {
                  e["selected"] = true;
                } else {
                  e["selected"] = false;
                }
              });
            }
          });
          self.accounts = accounts;
          self.$store.commit("setRefreshAccounts", false);
        })
        .catch(err => console.log(err));
    },
    allUnread(email) {
      let sum = 0;
      let task = this.accounts.find(account => account.email == email);
      task.folders.forEach(e => {
        sum += parseInt(e.unread);
      });
      if (sum != 0) {
        return sum;
      } else {
        return "";
      }
    },
    selectFolder(email, folderName, id) {
      this.accounts.forEach(e => {
        let thisEmail = false;
        if (e.email == email) {
          thisEmail = true;
        }
        e.folders.forEach(e => {
          if (thisEmail && e.id == id) {
            e.selected = true;
          } else {
            e.selected = false;
          }
        });
      });

      this.$store.commit("setNewFolder", { email: email, folderName: folderName,folderId: id });
      this.$store.commit("setEmailSelected", false);
      this.$store.commit("setFirstStart", false);
      this.$store.commit("setAllEmailFromList", false);
      this.$store.commit("setAllEmailFromHeader", false);
      this.$store.commit("setNewEmail", false);
      this.$store.commit("setWriteEmail", false);
    }
  }
};
</script>

<style scoped>
.cont {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
h3 {
  color: #444;
  text-align: center;
  margin: 15px 0 25px 0;
}
h6 {
  color: #444;
  cursor: pointer;
  padding: 5px;
  transition: all 0.3s ease;
}
h6:hover {
  cursor: pointer;
  background: rgb(222, 230, 247);
  transition: all 0.3s ease;
}
ul {
  padding: 0 0 10px 0;
  border-bottom: solid 2px #ccc;
}
li {
  display: flex;
  list-style: none;
  padding: 5px;
  transition: all 0.4s ease;
}
.icons {
  padding-right: 8px;
}
li:hover {
  cursor: pointer;
  background: rgb(222, 230, 247);
  transition: all 0.3s ease;
}
.active-folder {
  color: rgb(62, 62, 172);
  font-weight: bold;
}
.selected {
  background: rgb(222, 230, 247);
}
.label {
  display: flex;
}
.folderlist {
  padding-left: 25px;
}
.emails-container {
  padding-left: 350px;
  width: 100%;
  transition: all 0.5s ease;
}
.unread-nr {
  width: 100%;
  padding-right: 15px;
  margin-left: 20px;
  text-align: right;
}
.unreadbold {
  font-weight: bold;
}
.showContent {
  overflow: hidden;
  max-height: 100000px;
  padding-bottom: 10px;
  transition: max-height 0.8s ease-in;
}
.hideContent {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.8s ease-out;
  padding-bottom: 0;
}
.chevron {
  padding-right: 6px;
}
</style>