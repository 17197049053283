<template>
  <div class="cont">
    <div class="toptools">
      <div class="icons"><b-icon icon="box-arrow-right"></b-icon></div>
      <div @click="sendMessage()" class="icons-content">Küldés</div>
      <div class="icons"><b-icon icon="link45deg"></b-icon></div>
      <div @click="chooseFiles()" class="icons-content">Csatolás</div>
      <input
        @change="onFileSelected"
        id="fileUpload"
        type="file"
        ref="selectedFile"
        hidden
        multiple
      />
      <div class="icons"><b-icon icon="trash"></b-icon></div>
      <div class="icons-content">Elvetés</div>
      <div class="icons"><b-icon icon="three-dots"></b-icon></div>
    </div>
    <div v-if="fileList.length" class="fileslist">
      <div
        v-for="file in fileList"
        :key="file.name"
        class="filelist"
        :class="{ green: file.isUploaded }"
      >
        <b-icon icon="link45deg"></b-icon>{{ file.name }}
        <span
          v-if="file.isUploaded"
          @click="removeFile(file.name)"
          class="xcircle"
          ><b-icon icon="x-circle"></b-icon
        ></span>
        <span v-if="!file.isUploaded" class="xcircle"
          ><b-icon icon="hourglass-split"></b-icon
        ></span>
      </div>
    </div>
    <div class="fromTitle">Feladó:</div>
    <div class="fromSelect">
      <b-form-select
        v-model="selectedEmailAddr"
        :options="emailAddrList"
      ></b-form-select>
    </div>
    <div class="recipientsTitle">Címzettek:</div>
    <div class="recipient">
      <div class="recipientline">
        <b-form-input
          v-model="inputRecipient"
          list="input-list"
          id="input-with-list"
        ></b-form-input>
        <b-form-datalist id="input-list" :options="options"></b-form-datalist>
        <div @click="addRecipient()" class="addrecipient">Hozzáad</div>
      </div>
    </div>
    <div v-if="!inputRecipients.length" class="notice">
      Legalább egy címzettet kötelező adni...
    </div>
    <div v-if="inputRecipients.length" class="recipientsList">
      <div v-for="inp in inputRecipients" :key="inp" class="listElements">
        {{ inp }}
        <span @click="deleteRecipient(inp)" class="xcircle"
          ><b-icon icon="x-circle"></b-icon
        ></span>
      </div>
    </div>
    <div class="subject">
      <b-form-input
        :state="subjectState"
        v-model="subject"
        aria-describedby="subjectt-feedback"
        placeholder="Tárgy"
      ></b-form-input>
    </div>
    <div class="content">
      <b-form-textarea
        id="textarea"
        v-model="content"
        placeholder="Üzenet..."
        rows="16"
      ></b-form-textarea>
    </div>
  </div>
</template>

<script>
import Api from "../util/api";
import { emailValidation } from "../util/validation";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      recipients: "",
      subject: "",
      content: "",
      recipientState: null,
      subjectState: null,
      recipientsList: [],
      options: [],
      inputRecipient: "",
      inputRecipients: [],
      selectedFile: null,
      fileList: [],
      emailAddrList: [],
      selectedEmailAddr: "",
      allFileDone: false
    };
  },
  watch: {
    myEmailAddr: function (val) {
      if (val) {
        let i = 0;
        val.forEach((e) => {
          if (i == 0) {
            this.selectedEmailAddr = e;
          }
          i++;
          let emailAd = new Object();
          emailAd.value = e;
          emailAd.text = e;
          this.emailAddrList.push(emailAd);
        });
      }
    },
  },
  computed: {
    ...mapGetters(["myEmailAddr"]),
  },
  created() {
    let self = this;
    Api.getRecipientsList()
      .then(function (response) {
        self.recipientsList = response.data;
        self.recipientsList.forEach((e) => {
          let option = [];
          if (e.name) {
            option = e.email;
          } else {
            option = e.email;
          }
          self.options.push(option);
        });
      })
      .catch((err) => self.makeToast("Hiba","danger",err));
  },
  methods: {
    addRecipient() {
      if (this.inputRecipient) {
          if (emailValidation(this.inputRecipient)) {
            if(this.options.includes(this.inputRecipient)) {
              this.options = this.options.filter((e) => e != this.inputRecipient);
            }
            if(!this.inputRecipients.includes(this.inputRecipient)) {
              this.inputRecipients.push(this.inputRecipient);
              this.inputRecipient = "";
            } else {
              this.makeToast("Hiba","danger","Ez az email cím már szerepel a listában!");
            }
          } else {
            this.makeToast("Hiba","danger","Nem email formátum!");
          }
      }
    },
    deleteRecipient(inp) {
      this.inputRecipients = this.inputRecipients.filter((e) => e != inp);
      this.options.push(inp);
      this.options.sort();
      //console.log(this.inputRecipients);
    },
    chooseFiles() {
      document.getElementById("fileUpload").click();
    },
    onFileSelected() {
      this.selectedFile = this.$refs.selectedFile.files;
      //console.log(this.selectedFile);
      let formData = new FormData();
      for (var i = 0; i < this.selectedFile.length; i++) {
        if (!this.fileList.includes(this.selectedFile[i].name)) {
          this.fileList.push({
            name: this.selectedFile[i].name,
            isUploaded: false,
          });
        }
        let file = this.selectedFile[i];
        formData.append("files[" + i + "]", file);
      }
      //console.log(this.fileList);
      let self = this;
      Api.fileUpload(formData)
        .then(function (response) {
          let responseFiles = response.data;
          self.fileList.forEach((e) => {
            if (responseFiles.includes(e.name)) {
              e.isUploaded = true;
            }
          });
        })
        .catch((err) => self.makeToast("Hiba","danger",err));
    },
    removeFile(file) {
      this.fileList = this.fileList.filter((e) => e.name != file);
      //let self = this;
      Api.removeFile(file)
        .then(function () {
        })
        .catch((err) => console.log(err));
    },
    sendMessage() {
      if (this.inputRecipients.length) {
        this.allFileDone = true;
        this.fileList.forEach(e => {
          if(!e.isUploaded) {
            this.allFileDone = false;
          }
        });
        if(this.allFileDone) {  
          let self = this;
          Api.sendEmail(this.selectedEmailAddr, this.inputRecipients, this.subject, this.content)
            .then(function () {
              self.inputRecipients.forEach(e => {
                self.options.push(e);
              });
              self.options.sort();
              self.inputRecipients = [];
              self.subject = "";
              self.content = "";
              self.fileList = [];
              self.makeToast("Siker!","success","Sikeresen elküldve!");
            })
            .catch((err) => self.makeToast("Hiba","danger",err));
        } else {
          this.makeToast("Próbáld meg később...","warning","A csatolt fájlok még nem töltődtek fel!");
        }
      } else {
        this.makeToast("Hiba","danger","Nincs megadva címzett!");
      }
    },
    makeToast(title, variant, errorMessage) {
        this.$bvToast.toast(errorMessage, {
          title: title,
          autoHideDelay: 5000,
          //toaster: "b-toaster-bottom-right",
          variant: variant,
          solid: true
        })
      }
  },
};
</script>

<style scoped>
.cont {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}
.toptools {
  display: flex;
  margin: 0;
  padding: 0;
  height: 42px;
  line-height: 42px;
  width: 100%;
  background: rgb(238, 238, 238);
}
.icons {
  margin-left: 16px;
  color: rgb(6, 82, 139);
  cursor: pointer;
}
.icons-content {
  padding-left: 4px;
  color: rgb(6, 82, 139);
  cursor: pointer;
}
.icons-content:hover {
  text-shadow: 0px 0px black;
}
.recipient,
.subject,
.content,
.fromSelect {
  margin: 5px 5px 10px 5px;
}
.subject {
  padding-top: 15px;
  border-top: 1px solid #ddd;
}
.notice {
  font-size: 0.7rem;
  margin: -5px 0 15px 10px;
  color: #666;
}
.recipientsTitle,
.fromTitle {
  margin: 6px 10px;
  color: #666;
  font-weight: 500;
}
.recipientline {
  display: flex;
}
.addrecipient {
  color: rgb(6, 82, 139);
  line-height: 34px;
  margin: 0 10px;
  cursor: pointer;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 0 5px;
}
.addrecipient:hover {
  text-shadow: 0px 0px black;
  background: rgb(246, 246, 246);
}
.recipientsList,
.fileslist {
  display: flex;
  flex-wrap: wrap;
}
.listElements,
.filelist {
  margin: 5px 10px;
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 0.8rem;
  color: #666;
}
.filelist {
  color: #aaa;
}
.green {
  border: 1px solid rgb(42, 243, 42);
  color: #444;
}
.xcircle {
  cursor: pointer;
}
</style>