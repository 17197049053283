<template>
  <div>
    <div v-if="emailSelected">
      
      <h5 class="header">{{ email.subject }}</h5>
      <div class="buttons">
        <div class="butt">Válasz</div>
        <div class="butt">Válasz mindenkinek</div>
        <div class="butt">Továbbítás</div>
      </div>
      <div class="email-tools">
        <div v-if="email.fromname" class="details"><span>Feladó:</span> {{ email.fromname }}</div>
        <div v-if="email.fromname" class="details"> {{ email.from }}</div>
        <div v-if="!email.fromname" class="details"><span>Feladó:</span> {{ email.from }}</div>
        <div class="details"><span>Tárgy:</span> {{ email.subject }}</div>
        <div class="details"><span>Címzettek: </span>
          <span v-for="recipient in email.recipients" :key="recipient" class="recip">{{ recipient }}</span>
        </div>

        <div v-if="email.attachment" class="flexi">
          <div v-for="attachment in email.attachment" :key="attachment.name" class="flexiitem">
            <a :href="attachment.url" download>
              <div>
                <div>{{ attachment.name }}</div>
                <div class="size">{{ attachment.size }} <b-icon icon="link45deg"></b-icon></div>
              </div>
            </a>
          </div>
        </div>
      </div>
      
      <div v-if="!email.html" class="email-content">
        {{ email.content }}
      </div>
      <div v-if="email.html" v-html="email.content" class="email-content html-content">
      </div>
    </div>
  </div>
</template>

<script>
import Api from "../util/api";
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      email: ""
    };
  },
  watch: {
    emailSelected: function(val) {
      if (val) {
        let self = this;
        Api.getEmail(val)
          .then(function(response) {
            self.email = response.data;
            //console.log(self.email);
            //console.log(self.email.attachment);
          })
          .catch(err => console.log(err));
      }
    }
  },
  computed: {
    ...mapGetters(['emailSelected'])
  },
  created: function(){
    //console.log(this.emailSelected);
  }
};
</script>

<style scoped>
a {
  text-decoration: none;
  color: #333;
  display: block;
  padding: 5px 25px 5px 15px;
}
a:hover {
  text-decoration: none;
  color: #333;
}
.html-content{
  overflow: auto;
  max-width: 100%;
}
.header {
  padding: 0 37px;
  line-height: 40px;
  color: #444;
  background: #eee;
  border-bottom: 1px dotted #ddd;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.email-tools {
  border-bottom: 1px dotted #ccc;
  padding: 10px;
}
.details {
  font-size: 0.8rem;
  margin: 0 0 5px 10px;
}
.details span {
  text-shadow: 0px 0px black;
}
.email-content {
  padding: 17px;
  white-space: pre-wrap;
}
.buttons {
  display: flex;
  border-bottom: 1px dotted #ccc;
}
.butt {
  margin: 5px 0 10px 15px;
  color: rgb(0,50,180);
  cursor: pointer;
}
.butt:hover {
  text-shadow: 0px 0px black;
  text-decoration: underline;
}
.flexi {
  display: flex;
  flex-wrap: wrap;
  margin-top: 25px;
}
.flexiitem {
  color: #111;
  margin: 5px;
  border: 1px solid #ccc;
  border-radius: 2px;
}
.flexiitem:hover {
  background: #eee;
}
.size {
  color: #777;
  font-size: .8rem;
}
.recip {
  display: inline-block;
  padding: 3px 10px;
  margin: 5px;
  color: #555;
  border: 1px solid #ccc;
  border-radius: 15px;
}
@media (max-width: 999px) {
}
</style>