<template>
  <div class="home">
    <div class="top-tools"><emailtoptools /></div>
    <div class="inner-container">
      <div
        class="folders"
        :class="{ displaynonemobil: newFolder && !firstStart || writeEmail}"
      >
        <emailaccounts />
      </div>
      <div
        class="emails"
        :class="{
          displayblock: newFolder && !emailSelected,
          displayblockmobil: newFolder && !emailSelected && !firstStart,
          displaynone: emailSelected || writeEmail
        }"
      >
        <div class="emailstools">
          <emailstools />
        </div>
        <div class="emailheader">
          <emailsheader />
        </div>
        <div class="emailslist">
          <emails />
        </div>
      </div>
      <div
        class="email"
        :class="{
          displayblock: emailSelected && !writeEmail,
          displayblockmobil: emailSelected && !writeEmail,
        }"
      >
        <email />
      </div>
      <div class="writeEmail" :class="{displaynone: !writeEmail, displayblock: writeEmail}">
        <writeemail />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import emailaccounts from "../components/emailaccounts";
import emailtoptools from "../components/emailtoptools";
import emailstools from "../components/emailstools";
import emailsheader from "../components/emailsheader";
import emails from "../components/emails";
import email from "../components/email";
import writeemail from "../components/writeemail";

export default {
  name: "Home",
  components: {
    emailaccounts,
    emailtoptools,
    emailstools,
    emailsheader,
    emails,
    email,
    writeemail
  },
  computed: {
    ...mapGetters(["newFolder", "emailSelected", "firstStart", "writeEmail"]),
  },
};
</script>

<style scoped>
.home {
  height: 100%;
  width: 100%;
}
.top-tools {
  width: 100%;
  height: 40px;
  border-bottom: 1px solid #aaa;
}
.emailheader {
  height: 40px;
}
.inner-container {
  display: flex;
  flex-wrap: wrap;
  height: calc(100% - 40px);
}
.folders {
  background: #fff;
  height: 100%;
  padding: 10px;
  overflow: auto;
  width: 350px;
  transition: all 0.5s ease;
  border-right: 1px solid #ccc;
}
.folderlist {
  padding-left: 25px;
}
.emailstools {
  height: 40px;
}
.emailslist {
  height: calc(100% - 80px);
  overflow: auto;
}
.emails {
  width: calc(100% - 350px);
  height: 100%;
  border-bottom: 1px solid #ccc;
}
.email {
  width: calc(100% - 350px);
  height: 100%;
  overflow: auto;
  display: none;
}
.writeEmail {
  width: calc(100% - 350px);
  height: 100%;
  overflow: auto;
}
.displayblock {
  display: block;
}
.displaynone {
  display: none;
}
@media (max-width: 863px) {
  .email {
    width: 100%;
    display: none;
  }
  .writeEmail {
    width: 100%;
  }
  .emails {
    width: 100%;
    display: none;
  }
  .folders {
    width: 100%;
    border-right: none;
    transition: all 0.5s ease;
  }
  .displaynonemobil {
    display: none;
  }
  .displayblockmobil {
    display: block;
  }
}
</style>