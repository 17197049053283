var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home"},[_c('div',{staticClass:"top-tools"},[_c('emailtoptools')],1),_c('div',{staticClass:"inner-container"},[_c('div',{staticClass:"folders",class:{ displaynonemobil: _vm.newFolder && !_vm.firstStart || _vm.writeEmail}},[_c('emailaccounts')],1),_c('div',{staticClass:"emails",class:{
        displayblock: _vm.newFolder && !_vm.emailSelected,
        displayblockmobil: _vm.newFolder && !_vm.emailSelected && !_vm.firstStart,
        displaynone: _vm.emailSelected || _vm.writeEmail
      }},[_c('div',{staticClass:"emailstools"},[_c('emailstools')],1),_c('div',{staticClass:"emailheader"},[_c('emailsheader')],1),_c('div',{staticClass:"emailslist"},[_c('emails')],1)]),_c('div',{staticClass:"email",class:{
        displayblock: _vm.emailSelected && !_vm.writeEmail,
        displayblockmobil: _vm.emailSelected && !_vm.writeEmail,
      }},[_c('email')],1),_c('div',{staticClass:"writeEmail",class:{displaynone: !_vm.writeEmail, displayblock: _vm.writeEmail}},[_c('writeemail')],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }