import axios from "axios";

export default class Api {
  static getAccounts() {
    const url = process.env.VUE_APP_API_URL + "/api/accounts.php";
    return axios.post(url);
  }
  static getAccountsSettings() {
    const url = process.env.VUE_APP_API_URL + "/api/accountssettings.php";
    return axios.post(url);
  }
  static saveAccount(id,checked,username,password,serverIn,serverOut,imapPort,pop3Port,smtpPort) {
    const url = process.env.VUE_APP_API_URL + "/api/saveaccount.php";
    return axios.post(url, {
      id: id,
      checked: checked,
      username: username,
      password: password,
      serverIn: serverIn,
      serverOut: serverOut,
      imapPort: imapPort,
      pop3Port: pop3Port,
      smtpPort: smtpPort
    });
  }
  static deleteAccount(email) {
    const url = process.env.VUE_APP_API_URL + "/api/deleteaccount.php";
    return axios.post(url, {
      email: email
    });
  }
  static getEmails(email, folderId) {
    const url = process.env.VUE_APP_API_URL + "/api/emails.php";
    return axios.post(url, {
      email: email,
      folderId: folderId
    });
  }
  static getEmail(id) {
    const url = process.env.VUE_APP_API_URL + "/api/email.php";
    return axios.post(url, {
      id: id
    });
  }
  static deleteEmail(email, folderId, id) {
    const url = process.env.VUE_APP_API_URL + "/api/deleteemail.php";
    return axios.post(url, {
      email: email,
      folderId: folderId,
      id: id
    });
  }
  static moveEmails(email,to,from,id) {
    const url = process.env.VUE_APP_API_URL + "/api/moveemails.php";
    return axios.post(url, {
      email: email,
      to: to,
      from: from,
      id: id
    });
  }
  static star(email, folderId, emailId,star) {
    const url = process.env.VUE_APP_API_URL + "/api/star.php";
    return axios.post(url, {
      email: email,
      folderId: folderId,
      emailId: emailId,
      star: star
    });
  }
  static getRecipientsList() {
    const url = process.env.VUE_APP_API_URL + "/api/getrecipientslist.php";
    return axios.post(url);
  }
  static fileUpload(formData) {
    const url = process.env.VUE_APP_API_URL + "/api/fileupload.php";
    return axios.post(url, formData, {
      header: {
        "Content-Type": "multipart/form-data"
      }
    });
  }
  static removeFile(file) {
    const url = process.env.VUE_APP_API_URL + "/api/removefile.php";
    return axios.post(url, {
      file: file
    });
  }
  static sendEmail(from,to,subject,content) {
    const url = process.env.VUE_APP_API_URL + "/api/sendemail.php";
    return axios.post(url, {
      from: from,
      to: to,
      subject: subject,
      content: content
    });
  }
}