<template>
  <div class="emails-header">
    <div class="header-checkbox">
      <input type="checkbox" v-model="allchecked" @click="emailAll" />
    </div>
    <div v-show="emailChecked == 0" class="header-star"></div>
    <div v-show="emailChecked == 0" class="header-attachment"></div>
    <div v-show="emailChecked == 0" class="header-from">Feladó</div>
    <div v-show="emailChecked == 0" class="header-subject">Tárgy</div>
    <div v-show="emailChecked == 0" class="header-datum">Dátum</div>

    <div v-show="emailChecked > 0" @click="deleteAll" class="delete"><span><b-icon icon="trash"></b-icon></span><span>Törlés</span></div>
    <div v-show="emailChecked > 0" @click="move()" class="move"><span><b-icon icon="box-arrow-right"></b-icon></span><span>Áthelyezés</span></div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
        allchecked: false,
    };
  },
  watch: {
    allEmailFromList: function(val) {
      if (val) {
        this.allchecked = true;
      }
      else {
        this.allchecked = false;
      }
    }
  },
  computed: {
    ...mapGetters(['allEmailFromList','emailChecked'])
  },
  methods: {
    emailAll() {
      this.$store.commit("setAllEmailFromHeader", !this.allchecked);
    },
    deleteAll() {
      this.$store.commit("setDeleteAllEmails", true);
    },
    move() {
      this.$store.commit("setMove", true);
    }
  }
};
</script>

<style scoped>
.emails-header {
  display: flex;
  padding: 7px;
  border-bottom: 1px solid #ccc;
  background: white;
}
.header-checkbox,
.header-star,
.header-attachment {
  width: 20px;
}
.header-from {
  width: calc(25% - 20px);
}
.header-subject {
  width: calc(50% - 20px);
}
.header-datum {
  width: calc(25% - 20px);
}
.delete, .move {
  padding-left : 7px;
  cursor: pointer;
}
.delete:hover, .move:hover {
  font-weight: 500;
}
.move span, .delete span {
  display: inline-block;
  padding-left: 5px;
}

@media (max-width: 999px) {
  .header-from,
  .header-star,
  .header-attachment,
  .header-subject,
  .header-datum {
    display: none;
  }
}
</style>