import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    firsStart: true,
    emailSelected: false,
    newFolder: { "email": "", "folderName": "", "folderId": "" },
    allEmailFromList: false,
    allEmailFromHeader: false,
    emailFilter: false,
    emailSearch: false,
    emailChecked: 0,
    deleteAll: false,
    refreshAccounts: false,
    openedAccounts: [],
    newEmail: false,
    move: false,
    writeEmail: false,
    myEmailAddr: []
  },
  getters: {
    firstStart: state => { return state.firsStart },
    emailSelected: state => { return state.emailSelected },
    newFolder: state => { return state.newFolder },
    allEmailFromList: state => { return state.allEmailFromList },
    allEmailFromHeader: state => { return state.allEmailFromHeader },
    emailFilter: state => { return state.emailFilter },
    emailSearch: state => { return state.emailSearch },
    emailChecked: state => { return state.emailChecked },
    deleteAll: state => { return state.deleteAll },
    refreshAccounts: state => { return state.refreshAccounts },
    openedAccounts: state => { return state.openedAccounts },
    newEmail: state => { return state.newEmail },
    move: state => { return state.move },
    writeEmail: state => { return state.writeEmail },
    myEmailAddr: state => { return state.myEmailAddr }
  },
  mutations: {
    setFirstStart: (state, select) => (state.firsStart = select),
    setEmailSelected: (state, select) => (state.emailSelected = select),
    setNewFolder: (state, select) => (state.newFolder = select),
    setAllEmailFromList: (state, select) => (state.allEmailFromList = select),
    setAllEmailFromHeader: (state, select) => (state.allEmailFromHeader = select),
    setEmailFilter: (state, select) => (state.emailFilter = select),
    setEmailSearch: (state, select) => (state.emailSearch = select),
    setEmailChecked: (state, select) => (state.emailChecked = select),
    setDeleteAllEmails: (state, select) => (state.deleteAll = select),
    setRefreshAccounts: (state, select) => (state.refreshAccounts = select),
    setOpenedAccounts: (state, select) => (state.openedAccounts = select),
    setNewEmail: (state, select) => (state.newEmail = select),
    setMove: (state, select) => (state.move = select),
    setWriteEmail: (state, select) => (state.writeEmail = select),
    setMyEmailAddr: (state, select) => (state.myEmailAddr = select)
  },
  actions: {},
  modules: {}
});