<template>
  <div class="emails-tool">
    <div class="unread icon" @click="filterFunction('unread')">
      <b-icon icon="briefcase"></b-icon>
      <span class="icon-content">Olvasatlan</span>
    </div>
    <div class="star icon" @click="filterFunction('star')">
      <b-icon icon="star"></b-icon>
      <span class="icon-content">Csillagozott</span>
    </div>
    <div class="attachment icon" @click="filterFunction('attachment')">
      <b-icon icon="link45deg"></b-icon>
      <span class="icon-content">Melléklet</span>
    </div>
    <div class="search">
      <b-form-input
        size="sm"
        v-model="searchText"
        placeholder="Email keresése..."
      ></b-form-input>
    </div>
    <div class="searchicon" @click="search"><b-icon icon="search"></b-icon></div>
  </div>
</template>

<script>
export default {
    data() {
    return {
        searchText: ""
    };
  },
  methods: {
    filterFunction(filter) {
      this.$store.commit("setEmailFilter", filter);
    },
    search() {
      this.$store.commit("setEmailSearch", this.searchText);
    }
  }
};
</script>

<style scoped>
.emails-tool {
  display: flex;
  height: 40px;
  line-height: 40px;
  border-bottom: 1px solid #ccc;
  background: rgb(240, 240, 245);
  font-size: 0.9rem;
}
.icon {
  padding-left: 12px;
  cursor: pointer;
}
.icon:hover {
  color: black;
  text-shadow: 0px 0px #000;
}
.icon-content {
  display: inline-block;
  padding-left: 5px;
}
.search {
  margin: 5px 0 0 30px;
}
.searchicon {
  line-height: 40px;
  margin-left: 4px;
  padding-left: 4px;
  cursor: pointer;
}
@media (max-width: 863px) {
  .icon-content {
    display: none;
  }
}
</style>