<template>
  <div class="cont">
    <div v-if="newFolder && !firstStart" class="chevron" @click="backToFolders">
      <b-icon icon="chevron-left"></b-icon>
    </div>
    <div v-if="newFolder && !firstStart" class="foldername">
      {{ newFolder.folderName }}
    </div>
    <div
      class="newaccount"
      @click="addNewAccount"
      :class="{ hideinmobile: newFolder && !firstStart || writeEmail}"
    >
      <b-icon icon="gear"></b-icon>
      Email fiókok
    </div>
    <div
      class="newemail"
      @click="newEmail"
      :class="{ hideinmobile: newFolder && !firstStart || writeEmail }"
    >
      <b-icon icon="pencil"></b-icon>
      Új üzenet
    </div>
    <div
      class="writeemail"
      :class="{ showmobile: writeEmail }"
    >

        <div class="chevron" @click="backToFolders">
          <b-icon icon="chevron-left"></b-icon>
        </div>

            
  
      
    </div>
    <b-modal id="addNewAccountModal" title="Email fiók beállítások" hide-footer>
      <div v-if="!deleteAccount">
        <div class="accountstop" :class="{ accountstophide: accountstop }">
          <h5 class="account">Email fiókjaim</h5>
          <div
            v-for="account in accounts"
            :key="account.email"
            class="myAccount"
          >
            <div class="emailAddr" :class="{ default: account.default }">
              {{ account.email }}
            </div>
            <div @click="deleteAccount = account.email" class="iconTrash">
              <b-icon icon="trash"></b-icon>
            </div>
            <div @click="writeAccount(account.email)" class="iconPencil">
              <b-icon icon="pencil"></b-icon>
            </div>
          </div>
          <div @click="addAccount()" class="new">
            <b-icon icon="plus-circle"></b-icon> Új email fiók hozzáadása
          </div>
        </div>
        <div class="newformhide" :class="{ newformshow: newFormShow }">
          <h5 v-if="writeOrNew" class="account">Új fiók hozzáadása</h5>
          <h5 v-if="!writeOrNew" class="writeaccount">Fiók módosítása</h5>
          <div v-if="!writeOrNew" class="writeaccountemail">({{ email }})</div>
          <div class="defaultcheckbox">
            <input type="checkbox" v-model="checked" /> Alapértelmezett fiók
          </div>

          <div class="label email-label">Email cím</div>

          <b-form-input
            :state="usernameState"
            v-model="username"
            aria-describedby="username-feedback"
            placeholder="Email cím"
          ></b-form-input>

          <b-form-invalid-feedback id="username-feedback">
            A mező kitöltése kötelező!
          </b-form-invalid-feedback>

          <div class="label password-label">Jelszó</div>

          <b-form-input
            :state="passwordState"
            v-model="password"
            type="password"
            aria-describedby="password-feedback"
            placeholder="Jelszó"
          ></b-form-input>

          <b-form-invalid-feedback id="password-feedback">
            A mező kitöltése kötelező!
          </b-form-invalid-feedback>

          <h5>Bejövő kiszolgáló</h5>

          <div class="label serverin-label">Szerver</div>

          <b-form-input
            :state="serverInState"
            v-model="serverIn"
            aria-describedby="serverIn-feedback"
            placeholder="Szerver url"
          ></b-form-input>

          <b-form-invalid-feedback id="serverIn-feedback">
            A mező kitöltése kötelező!
          </b-form-invalid-feedback>

          <div class="label imap-label">IMAP Port</div>

          <b-form-input
            :state="imapPortState"
            v-model="imapPort"
            aria-describedby="imapPort-feedback"
            placeholder="IMAP Port"
          ></b-form-input>

          <b-form-invalid-feedback id="imapPort-feedback">
            A mező kitöltése kötelező!
          </b-form-invalid-feedback>

          <div class="label pop3-label">POP3 Port</div>

          <b-form-input
            v-model="pop3Port"
            placeholder="POP3 Port"
          ></b-form-input>

          <h5>Kimenő kiszolgáló</h5>

          <div class="label serverout-label">Szerver</div>

          <b-form-input
            :state="serverOutState"
            v-model="serverOut"
            aria-describedby="serverOut-feedback"
            placeholder="Szerver url"
          ></b-form-input>

          <b-form-invalid-feedback id="serverOut-feedback">
            A mező kitöltése kötelező!
          </b-form-invalid-feedback>

          <div class="label smtp-label">SMTP Port</div>

          <b-form-input
            :state="smtpPortState"
            v-model="smtpPort"
            aria-describedby="smtpPort-feedback"
            placeholder="SMTP Port"
          ></b-form-input>

          <b-form-invalid-feedback id="smtpPort-feedback">
            A mező kitöltése kötelező!
          </b-form-invalid-feedback>

          <div class="buttons">
            <div>
              <b-button @click="back()" class="mbutton" variant="primary"
                >Mégse</b-button
              >
            </div>
            <div>
              <b-button @click="save()" class="mbutton" variant="primary"
                >Mentés</b-button
              >
            </div>
          </div>
        </div>
      </div>
      <div v-if="deleteAccount">
        <h6>Biztosan törlöd ezt a fiókot?</h6>
        {{ deleteAccount }}
        <div class="buttons">
          <div>
            <b-button @click="back()" class="mbutton" variant="primary"
              >Mégse</b-button
            >
          </div>
          <div>
            <b-button @click="deleteAcc()" class="mbutton" variant="primary"
              >Törlés</b-button
            >
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Api from "../util/api";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      accounts: [],
      id: "",
      email: "",
      checked: false,
      username: "",
      password: "",
      serverIn: "",
      imapPort: "",
      pop3Port: "",
      serverOut: "",
      smtpPort: "",
      newFormShow: false,
      writeOrNew: false,
      accountstop: false,
      deleteAccount: false,
      usernameState: null,
      passwordState: null,
      serverInState: null,
      serverOutState: null,
      imapPortState: null,
      smtpPortState: null,
    };
  },
  created() {},
  computed: {
    ...mapGetters(["newFolder", "emailSelected", "firstStart", "writeEmail"]),
  },
  methods: {
    backToFolders() {
      if(this.writeEmail) {
        this.$store.commit("setWriteEmail", false);
      }
      if (this.emailSelected) {
        this.$store.commit("setEmailSelected", false);
      } else {
        this.$store.commit("setNewFolder", false);
      }
    },
    addNewAccount() {
      this.newFormShow = false;
      this.writeOrNew = false;
      this.accountstop = false;
      this.$bvModal.show("addNewAccountModal");
      let self = this;
      Api.getAccountsSettings()
        .then(function (response) {
          self.accounts = response.data;
        })
        .catch((err) => console.log(err));
    },
    newEmail() {
      this.$store.commit("setWriteEmail", true);
    },
    writeAccount(email) {
      let acc = this.accounts.find((task) => task.email == email);
      this.id = acc.id;
      this.email = acc.email;
      this.checked = acc.default;
      this.username = acc.name;
      this.password = acc.password;
      this.serverIn = acc.serverin;
      this.serverOut = acc.serverout;
      this.imapPort = acc.imap;
      this.pop3Port = acc.pop3;
      this.smtpPort = acc.smtp;

      this.newFormShow = true;
      this.writeOrNew = false;
      this.accountstop = true;
    },
    addAccount() {
      this.checked = false;
      this.id = "";
      this.username = "";
      this.password = "";
      this.serverIn = "";
      this.serverOut = "";
      this.imapPort = "";
      this.pop3Port = "";
      this.smtpPort = "";

      this.newFormShow = true;
      this.writeOrNew = true;
      this.accountstop = true;
    },
    back() {
      this.newFormShow = false;
      this.writeOrNew = false;
      this.accountstop = false;
      this.deleteAccount = false;

      this.usernameState = null;
      this.passwordState = null;
      this.serverInState = null;
      this.serverOutState = null;
      this.imapPortState = null;
      this.smtpPortState = null;
    },
    save() {
      let faultData = false;
      if (this.username.length == 0) {
        this.usernameState = false;
        faultData = true;
      }
      if (this.password.length == 0) {
        this.passwordState = false;
        faultData = true;
      }
      if (this.serverIn.length == 0) {
        this.serverInState = false;
        faultData = true;
      }
      if (this.serverOut.length == 0) {
        this.serverOutState = false;
        faultData = true;
      }
      if (this.imapPort.length == 0) {
        this.imapPortState = false;
        faultData = true;
      }
      if (this.smtpPort.length == 0) {
        this.smtpPortState = false;
        faultData = true;
      }
      if (!faultData) {
        let self = this;
        Api.saveAccount(
          this.id,
          this.checked,
          this.username,
          this.password,
          this.serverIn,
          this.serverOut,
          this.imapPort,
          this.pop3Port,
          this.smtpPort
        )
          .then(function () {
            self.addNewAccount();
          })
          .catch((err) => console.log(err));
      }
    },
    deleteAcc() {
      let self = this;
      Api.deleteAccount(this.deleteAccount)
        .then(function () {
          self.deleteAccount = false;
          self.addNewAccount();
        })
        .catch((err) => console.log(err));
    },
  },
};
</script>

<style scoped>
.cont {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  display: flex;
  height: 40px;
  line-height: 40px;
  margin: 0;
  padding-left: 25px;
  color: #333;
  background: rgb(222, 230, 247);
  border-bottom: 1px solid #ccc;
}
.chevron {
  font-size: 1.4rem;
  margin-top: -1px;
  margin-left: -15px;
  margin-right: 20px;
  display: none;
}
.newaccount {
  cursor: pointer;
}
.newemail {
  padding-left: 25px;
  cursor: pointer;
}
.newemail:hover,
.newaccount:hover {
  color: black;
  text-shadow: 0px 0px black;
}
.foldername {
  font-weight: 700;
  color: #333;
  display: none;
}
h5 {
  margin: 25px 0 0 5px;
}
.account {
  margin-top: 0px;
  margin-bottom: 15px;
}
.writeaccount {
  margin-top: 0;
}
.writeaccountemail {
  margin: 0 0 20px 5px;
}
.label {
  margin: 15px 0 5px 5px;
}
.myAccount {
  display: flex;
  border-bottom: 1px dotted #ccc;
  margin-bottom: 15px;
  margin-left: 5px;
}
.emailAddr {
  width: 100%;
}
.iconTrash,
.iconPencil {
  padding-left: 10px;
  cursor: pointer;
}
.iconTrash:hover,
.iconPencil:hover {
  text-shadow: 0px 0px black;
}
.default {
  font-weight: 700;
}
.new {
  margin: 5px 0 25px 5px;
  cursor: pointer;
  color: rgb(0, 100, 255);
  display: inline-block;
}
.new:hover {
  text-shadow: 0px 0px black;
  text-decoration: underline;
}
.newformhide {
  display: none;
}
.newformshow {
  display: block;
}
.defaultcheckbox {
  margin: 0 0 15px 5px;
}
.accountstophide {
  display: none;
}
.buttons {
  display: flex;
}
.mbutton {
  margin: 10px 15px 0 0;
}
.writeemail {
  display: none;
}
@media (max-width: 863px) {
  .chevron {
    display: block;
  }
  .foldername {
    display: block;
  }
  .hideinmobile {
    display: none;
  }
  .showmobile {
    display: block;
  }
}
</style>